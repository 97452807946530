
























































































































































































import { Vue } from 'vue-property-decorator';
import Card from '@/components/Cards/Card.vue';
import { RealtimeMarket, TvNetwork } from '@/data/dto/realtime-market.dto';

export default Vue.extend({
  name: 'RealtimeMarketPanel',
  components: {
    Card,
  },
  async beforeMount() {
    await this.getRealtimeMarketsConfig();
  },
  methods: {
    orderTvNetworksTable() {
      this.realtimeMarketTable.tvNetworks.sort((a, b) => b.order - a.order);
    },
    async getRealtimeMarketsConfig() {
      try {
        const realtimeMarketConfig = await this.$store.dispatch('getRealtimeMarketsConfig');
        this.marketTvNetworks = realtimeMarketConfig;
        this.marketList = realtimeMarketConfig
          .map((marketTvNetwork: RealtimeMarket) => marketTvNetwork.id);
      } catch (error) {
        this.$notification.notify({
          message: 'Não foi possível completar a operação. Por favor verifique a sua conexão ou tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'danger',
          timeout: 3000,
        });
      }
    },
    async saveMarket() {
      try {
        const realtimeMarket = this.realtimeMarketTable;
        realtimeMarket.adPath = this.adPathSelected.split(',');
        await this.$store.dispatch('updateRealtimeMarketsConfig', realtimeMarket);
        this.orderTvNetworksTable();
        this.$notification.notify({
          message: 'Praça atualizada com sucesso!',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 2000,
        });
        this.$emit('close');
      } catch {
        this.$notification.notify({
          message: 'Não foi possivel executar a operação. Por favor tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'danger',
          timeout: 3000,
        });
      }
    },
    addRowHandler() {
      const lastRow = this.realtimeMarketTable.tvNetworks
        .sort((a, b) => b.order - a.order)[0];
      const order = lastRow ? Number(lastRow.order) + 1 : 0;
      const newRow = {
        order,
        id: '',
        isDefault: false,
        isRTDefault: false,
        isCalculated: false,
        kantarRealtimeApiTvNetworkId: 0,
      } as TvNetwork;
      this.realtimeMarketTable.tvNetworks.unshift(newRow);
    },
    deleteRowHandler({ index }: any) {
      this.realtimeMarketTable.tvNetworks.splice(index, 1);
    },
  },
  data() {
    return {
      marketId: null,
      marketList: [] as string[],
      marketTvNetworks: [] as RealtimeMarket[],
      realtimeMarketTable: { } as RealtimeMarket,
      adPathSelected: '',
      fields: [
        { key: 'order', label: 'Order' },
        { key: 'id', label: 'Id Realtime' },
        { key: 'kantarRealtimeApiTvNetworkId', label: 'Id Kantar' },
        { key: 'isCalculated', label: 'Is Calculated' },
        { key: 'isDefault', label: 'Is Default' },
        { key: 'isRTDefault', label: 'Is RT Default' },
        'Ações',
      ],
      options: [
        { value: 'true', text: 'Sim' },
        { value: 'false', text: 'Não' },
      ],
    };
  },
  watch: {
    marketId(marketId) {
      const realtimeMarketSelected = this.marketTvNetworks.find((market) => market.id === marketId);
      if (realtimeMarketSelected) {
        this.realtimeMarketTable = realtimeMarketSelected;
        this.adPathSelected = realtimeMarketSelected.adPath.toString();
        this.orderTvNetworksTable();
      }
    },
  },
});
