























import Vue from 'vue';

export default Vue.extend({
  name: 'card',
  props: {
    title: {
      type: String,
    },
    subTitle: {
      type: String,
    },
    type: {
      type: String,
    },
    headerClasses: {
      type: [String, Object, Array],
    },
    bodyClasses: {
      type: [String, Object, Array],
    },
    footerClasses: {
      type: [String, Object, Array],
    },
  },
});

