































































































import { Vue } from 'vue-property-decorator';
import Card from '@/components/Cards/Card.vue';
import { MarketDTO } from '@/data/dto/market.dto';
import { ReprocessingDTO } from '@/data/dto/reprocessing.dto';

export default Vue.extend({
  name: 'CreateReprocessing',
  components: {
    Card,
  },
  beforeMount() {
    this.$store.dispatch('getAllMarketsNames')
      .then((res: MarketDTO[]) => {
        this.marketList = res;
      })
      .catch(() => {
        this.$notification.notify({
          message: 'Não foi possível completar a operação. Por favor verifique a sua conexão ou tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'danger',
          timeout: 3000,
        });
      });
  },
  watch: {
    marketType() {
      this.$data.marketId = null;
    },
  },
  methods: {
    async submit() {
      try {
        const reprocessingDTO: ReprocessingDTO = {
          fileType: this.fileType,
          marketType: this.marketType,
          marketId: this.marketId,
          startDate: this.startDate,
          endDate: this.endDate,
        };
        await this.$store.dispatch('addReprocessing', reprocessingDTO);
        this.$notification.notify({
          message: 'Evento reprocessado com sucesso!',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 2000,
        });
        this.$emit('close');
      } catch {
        this.$notification.notify({
          message: 'Não foi possivel executar a operação. Por favor tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'danger',
          timeout: 3000,
        });
      }
    },
  },
  computed: {
    isDisabled() {
      return !(this.$data.marketType && this.$data.fileType
         && this.$data.marketId && this.$data.startDate && this.$data.endDate)
         || (this.$data.endDate < this.$data.startDate);
    },
    filteredMarkets() {
      if (this.$data.marketType === 'special') {
        return this.$data.marketList.filter((x: MarketDTO) => x.isSpecial === true);
      } if (this.$data.marketType === 'regular') {
        return this.$data.marketList.filter((x: MarketDTO) => x.isSpecial === false);
      }
      return this.$data.marketList;
    },
  },
  data() {
    return {
      fileType: [],
      marketType: 'regular' as 'regular' | 'special',
      marketId: '',
      startDate: '',
      endDate: '',
      marketList: [] as MarketDTO[],
    };
  },
});
